//首页模块

//引入接口函数
import request from '@/utils/request'


//获取轮播图
export const homeSwiper= query=>{
    return request({
        method:'get',
        url:'/qct/carousel/selectCarouselList',
        query,
    })
}

//获取菜单信息
export function getMenu(id) {
    return request({
      url: `/qct/content/selectContentListByMenuId?menuId=${id}`,
      method: "get",
    });
}

//获取菜单 带页码的
export function getMenuPage(query) {
  return request({
    url: `/qct/content/selectContentListByMenuId`,
    method: "get",
    params:query
  });
}

//获取站点信息
export const getFooterData= query=>{
  return request({
      method:'get',
      url:'/qct/site/selectSiteList',
      query,
  })
}


//获取公司信息
export const getCompanyData= query=>{
  return request({
      method:'get',
      url:'/qct/company/selectCompanyList',
      query,
  })
}

//下载文件信息
export const getFileData= query=>{
  return request({
      method:'get',
      url:`/qct/common/download?fileName=${query}`,
      responseType: 'arraybuffer'
  })
}

//获取文件详情
export const getDetail= id=>{
  return request({
      method:'get',
      url:`/qct/content/`+id,
  })
}

//在线留言
export const sendMessage= data=>{
  return request({
      method:'post',
      url:"/qct/message",
      data:data,
  })
}
