<template>
    <div class="header">
        <div class="menu-wrapper df">
            <!-- 头部顶部 -->
            <div class="header-top">
                <img src="@/assets/img/logo.png" alt="">
            </div>
            <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" @select="handleSelect" router
                background-color="#f5f5f5" text-color="#000" active-text-color="red">
                <!-- key不能绑在template虚拟标签上 -->
                <template v-for="(item,index) in routes">
                    <!-- 没儿子 -->
                    <el-menu-item class="menu" :index="item.path" v-if="!item.hidden && !item.children" :key="item.path">
                        <span slot="title">{{ item.meta.title }}</span>
                    </el-menu-item>

                    <!-- 有儿子 -->
                    <el-submenu :index="item.meta.path" v-if="!item.hidden && item.children?.length > 0"
                        :key="item.path" >
                        <template slot="title">
                            <span @click="toTitlePath(item,index)" class="sub-menu" :class="{'active':isAddActive==item.meta.title}">{{ item.meta.title }}</span>
                        </template>
                        <el-menu-item class="menu"  v-for="subItem in item.children" v-if="!subItem.hidden && !subItem.meta.hidden"
                            :key="subItem.path" :index="subItem.path" style="text-align: center;">{{ subItem.meta?.title}}</el-menu-item>
                    </el-submenu>
                </template>
            </el-menu>
            <div class="phone-menu">
                <i class="el-icon-s-grid" @click="isShow = !isShow"></i>
            </div>
        </div>
        <!-- 手机端导航 -->
        <el-collapse v-show="isShow" v-model="activeNames">
            <template v-for="item in routes">
                <div class="collapse-title-only" v-if="!item.hidden && !item.children" :key="item.path"
                    @click="toMenu(item)">
                    {{ item.meta.title }}
                </div>
                <el-collapse-item :name="item.path" v-if="!item.hidden && item.children?.length > 0" :key="item.path"
                    :title="item.children[0].meta.title">
                    <div class="collapse-title" v-for="subItem in item.children"
                        v-if="!subItem.hidden && !subItem.meta.hidden" :key="subItem.path" @click="toMenu(subItem)">{{
                subItem.meta?.title }}</div>
                </el-collapse-item>
            </template>

        </el-collapse>
    </div>
</template>

<script>

export default {
    data() {
        return {
            activeIndex: '',
            isShow: false,
            isAddActive:'',
            activeNames: []
        }
    },
    watch: {
        '$route': 'getPath'
    },
    computed: {
        routes() {
            return this.$router.options.routes
        },
    },
    created() {
     
    },
    mounted(){
     

        let menu =document.querySelectorAll('.menu')
        menu.forEach(item=>{
            item.addEventListener('click',()=>{
                sessionStorage.setItem('activeMenu',null)
                this.isAddActive=sessionStorage.getItem('activeMenu') || ''
            })
        })
        
    },
    methods: {
        handleSelect(data) {
        },
        // 手机端跳转
        toMenu(data) {
            this.isShow = false
            this.activeNames = []
            this.$router.push(data.path)
        },
        getPath() {
            
            var routes = {
                children: this.$router.options.routes
            };
            var route = this.$route.matched;
            for (var i = 0; i < route.length - 1; i++) {
                routes = routes.children.find((e) => (e.meta == route[i].meta));
            }
            this.$store.commit("user/getPath", routes.children);

            this.isAddActive=sessionStorage.getItem('activeMenu') || ''
        },
        toTitlePath(item) {
            this.$router.push(item.children[0].path)
            this.isAddActive=item.meta.title
            sessionStorage.setItem('activeMenu',item.meta.title)
        }
    },


}
</script>

<style lang="scss" scoped>
$type: screen;
$proto: max-width;
$value: 600px;

.header {
    position: fixed;
    top: 0;
    background-color: #fff;
    width: 100%;
    z-index: 999;

    .menu-wrapper {
        width: 100%;
        background-color: #f5f5f5;
        align-items: center;

        .header-top {
            padding-left: 5%;
            margin-right: 130px;
        }

        ::v-deep .el-menu-demo {
            
            // 隐藏 el-submenu 下的箭头
            .el-submenu__icon-arrow {
                display: none;
            }

            .el-menu-item {
                padding: 0;
                margin: 0 30px;
                border-bottom: none !important;
            }
           
            .el-submenu__title {
                padding: 0;
                margin: 0 30px;
                border-bottom: none !important;
            }
        }
        .sub-menu{
          &.active{
             color: red;
          }
        }
        .phone-menu {
            display: none;
        }
    }
}

@media #{$type} and ($proto: $value) {
    .menu-wrapper{
      justify-content: space-between
    }
    ::v-deep .el-menu-demo {
        display: none;
    }
   
    .header-top {
        img {
            height: 30px !important;
            padding: 10px;
        }
    }

    .phone-menu {
        display: block !important;
        padding-right: 30px;
        font-size: 20px;
    }

    ::v-deep .el-collapse {

        .collapse-title-only {
            line-height: 40px;
            border-bottom: 1px solid #EBEEF5;

        }

        .el-collapse-item__header {
            height: 40px;
        }

        .el-collapse-item__content {
            padding-bottom: 10px;
        }
    }
}
</style>
<style>
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
    min-width: 120px !important;
}
.el-menu--horizontal>.el-menu-item.is-active{
  border-bottom: none !important;
}
.el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border-bottom: none !important;
}
</style>