<template>
    <div class="sid-bar">
        <div class="content">
            <div class="item" v-for="(item, index) in routePath" :key="index" @click="toPath(item, index)"
            :class="{'active': $route.path==item.path}">
            <span class="line"></span> {{ item.meta.title }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
       
        }
    },

    computed: {
        ...mapState('user', ['routePath']),

    },
    mounted(){
    
    },
    methods: {
        toPath(data, index) {   
            this.$router.push(`${data.path}`)
            localStorage.setItem('activeMenu',data.meta.title)
            
        },

    }
}
</script>

<style lang="scss" scoped>
.sid-bar {
    padding-top: 10px;
    .content {
        .item {
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
            margin-bottom: 10px;
            color: #323232;
            display: flex;
            padding-left: 10px;
            align-items: center;
            cursor: pointer;
            font-size: 15px;
            .line {
                width: 3px;
                height: 12px;
                background-color: #ccc;
                display: block;
                padding-top: 2px;
                margin-right: 10px;
            }

            &:hover {
                color: red;
                .line{
                    background-color: red; 
                }
            }
        }

        .active {
            color: red;
            .line{
                    background-color: red; 
            }
        }

        .item:first-child {
            font-size: 20px;
            font-weight: bolder;
            padding-bottom: 15px;

            .line {
                display: none;
            }
        }

    }
}
</style>