<template>
  <div class="layout">
    <div class="layout-top">
      <img src="@/assets/img/13.png" alt="">
    </div>
    <div class="layout-content">
      <div class="left .hidden-xs">
        <sidBar></sidBar>
      </div>
      <div class="right">
        <navBar></navBar>
        <mainVue></mainVue>
      </div>
    </div>

  </div>
</template>

<script>
import mainVue from './main.vue';
import navBar from './navBar.vue';
import sidBar from './sidBar.vue';


export default {
  components: {
    mainVue,
    navBar,
    sidBar,
  },
  data() {
    return {
    }
  },
  mounted() {
  },


}
</script>

<style lang="scss" scoped>
$type: screen;
$proto: max-width;
$value: 600px;

.layout {
  margin-top: 50px;

  .layout-top {
    img {
      width: 100%;
      height: 400px;
    }
  }

  .layout-content {
    padding: 20px 50px;
    display: flex;
    justify-content: space-between;
    font-family: Arial, sans-serif;
    margin-bottom: 20px;

    .left {
      width: 10%;
      margin-right: 20px;
    }

    .right {
      flex: 1;
      padding: 10px 30px;
      width: 70%;
      padding: 20px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow:
      /* 底部阴影 */
        0 5px 15px rgba(0, 0, 0, 0.1),
        /* 顶部阴影 */
        0 -5px 15px rgba(0, 0, 0, 0.1),
        /* 右侧阴影 */
        5px 0 15px rgba(0, 0, 0, 0.1),
        /* 左侧阴影 */
        -5px 0 15px rgba(0, 0, 0, 0.1);
      /* 可选：添加一些变换以增强立体效果 */
      transition: all 0.3s ease;
      /* 平滑过渡效果 */
    }
  }


}

@media #{$type} and ($proto: $value) {
  .layout{
    margin-top: 10px;
  }
    .left{
      display: none;
    }
    .right{
      margin: 10px ;
    }
    .layout-content{
      padding: 0 2px !important;
    }
    .layout-top {
      img {
        height: 200px !important;
    }
  }
}
</style>