import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/layout.vue';
Vue.use(VueRouter)

//隐藏原地跳转错误
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
 return originalPush.call(this, location).catch(err => err)
}


const routes = [

  // 首页重定向
  {
    path: '/',
    hidden:true,//不在导航列表中显示
    redirect: '/home'
  },
  {
    path:'*',
    hidden:true,
    meta: { path: '*', title: '404' },
    redirect:'/404'
   },
  {
    path:'/404',
    hidden:true,
    meta: { path: '/404', title: '404' },
    component:() =>import("@/views/404/error404.vue")
  },
  //首页
  {
    path: '/home',
    meta: { path: '/home', title: '首页' },
    component: () => import('@/views/home'),
  },
   //关于全策通
   {
    path: '/about',
    component: Layout,
    meta: { path: '/about', title: '关于全策通' },
    redirect: '/about/index',
    children: [
      {
        path: '/about/index',
        meta: { path: '/about/index', title: '关于全策通',hidden:true,index: 1 },
        component: () => import('@/views/about/index.vue')
      },
      {
        path: '/about/blurb',
        meta: { path: '/about/blurb', title: '企业简介',hidden:false,index: 2},
        component: () => import('@/views/about/blurb.vue')
      },
      {
        path: '/about/culture',
        meta: { path: '/about/culture', title: '企业文化',hidden:false,index: 3 },
        component: () => import('@/views/about/culture.vue')
      },
      {
        path: '/about/idea',
        meta: { path: '/about/idea', title: '经营理念',hidden:false, },
        component: () => import('@/views/about/idea.vue')
      }
    ]
  },
  //咨询中心
  {
    path: '/consult',
    component: Layout,
    meta: { path: '/consult', title: '咨询中心' },
    redirect: '/consult/index',
    children: [
      {
        path: '/consult/index',
        meta: { path: '/consult/index', title: '咨询中心',hidden:true,keepAlive: true, isBack: false  },
        component: () => import('@/views/consult/index.vue')
      },
      {
        path: '/consult/industry',
        meta: { path: '/consult/industry', title: '行业动态',hidden:false,keepAlive: true, isBack: false },
        component: () => import('@/views/consult/industry.vue')
      },
      {
        path: '/consult/company',
        meta: { path: '/consult/company', title: '公司动态',hidden:false,keepAlive: true, isBack: false  },
        component: () => import('@/views/consult/company.vue')
      },
      {
        path: '/consult/detail',
        hidden:true,//不在导航列表中显示
        name:'consultDetail',
        component: () => import('@/views/consult/components/detail.vue')
      },
    ]
  },

  //产品&服务
  {
    path: '/product',
    component: Layout,
    meta: { path: '/product', title: '产品&服务' },
    redirect: '/product/index',
    children: [
      {
        path: '/product/index',
        meta: { path: '/product/index', title: '产品&服务' ,hidden:true},
        component: () => import('@/views/product/index.vue')
      },
      {
        path: '/product/bicarbon',
        meta: { path: '/product/bicarbon', title: '双碳咨询' ,hidden:false},
        component: () => import('@/views/product/bicarbon.vue')
      },
      {
        path: '/product/conversion',
        meta: { path: '/product/industry', title: '成果转化' ,hidden:false},
        component: () => import('@/views/product/conversion.vue')
      },
      {
        path: '/product/dcmm',
        meta: { path: '/product/dcmm', title: 'DCMM',hidden:false },
        component: () => import('@/views/product/dcmm.vue')
      },
      {
        path: '/product/esg',
        meta: { path: '/product/esg', title: 'ESG咨询',hidden:false },
        component: () => import('@/views/product/esg.vue')
      },
      {
        path: '/product/fuse',
        meta: { path: '/product/fuse', title: '两化融合',hidden:false },
        component: () => import('@/views/product/fuse.vue')
      },
      {
        path: '/product/message',
        meta: { path: '/product/message', title: '信息化项目',hidden:false },
        component: () => import('@/views/product/message.vue')
      },
      {
        path: '/product/project',
        meta: { path: '/product/project', title: '项目申报',hidden:false },
        component: () => import('@/views/product/project.vue')
      },
      {
        path: '/product/research',
        meta: { path: '/product/research', title: '产业研究',hidden:false },
        component: () => import('@/views/product/research.vue')
      },
      {
        path: '/product/green',
        meta: { path: '/product/green', title: '绿色制造体系',hidden:false },
        component: () => import('@/views/product/green.vue')
      },
      {
        path: '/product/detail',
        hidden:true,//不在导航列表中显示,
        name:'productDetail',
        component: () => import('@/views/product/components/detail.vue')
      },
    ]
  },

  //客户案例
  {
    path: '/case',
    component: Layout,
    meta: { path: '/case', title: '客户案例' },
    redirect: '/case/index',
    children: [
      {
        path: '/case/index',
        meta: { path: '/case/index', title: '客户案例',hidden:true,keepAlive: true, isBack: false  },
        component: () => import('@/views/case/index.vue')
      },
      {
        path: '/case/advice',
        meta: { path: '/case/advice', title: '工业咨询案例',hidden:false,keepAlive: true, isBack: false  },
        component: () => import('@/views/case/advice.vue')
      },
      {
        path: '/case/eag',
        meta: { path: '/case/eag', title: 'EAG评估',hidden:false,keepAlive: true, isBack: false  },
        component: () => import('@/views/case/eag.vue')
      },
      {
        path: '/case/lowCarbon',
        meta: { path: '/case/lowCarbon', title: '低碳节能案例',hidden:false,keepAlive: true, isBack: false  },
        component: () => import('@/views/case/lowCarbon.vue')
      },
      {
        path: '/case/outcome',
        meta: { path: '/case/outcome', title: '成果转化案例',hidden:false,keepAlive: true, isBack: false  },
        component: () => import('@/views/case/outcome.vue')
      },
      {
        path: '/case/detail',
        hidden:true,//不在导航列表中显示
        component: () => import('@/views/case/components/detail.vue')
      },
    ]
  },
   //加入我们
   {
    path: '/add',
    component: Layout,
    meta: { path: '/add', title: '加入我们' },
    redirect: '/add/index',
    children: [
      {
        path: '/add/index',
        meta: { path: '/add/index', title: '加入我们' ,hidden:true},
        component: () => import('@/views/add/index.vue')
      },
      {
        path: '/add/campus',
        meta: { path: '/add/campus', title: '校园招聘' ,hidden:false},
        component: () => import('@/views/add/campus.vue')
      },
      {
        path: '/add/society',
        meta: { path: '/add/society', title: '社会招聘',hidden:false },
        component: () => import('@/views/add/society.vue')
      },
      {
        path: '/add/detail',
        hidden:true,//不在导航列表中显示
        component: () => import('@/views/add/components/detail.vue')
      },
    ]
   },

    //联系我们
  {
    path: '/contact',
    meta: { path: '/contact', title: '联系我们' },
    component: () => import('@/views/contact/index.vue'),
  },
   //政策大模型云平台
   {
    path: '/matching',
    meta: { path: '/matching', title: '政策大模型云平台' },
    component: () => import('@/views/matching/index.vue'),
  },
  //  {
  //   path: '/matching',
  //   component: Layout,
  //   meta: { path: '/matching', title: '系统匹配精灵' },
  //   redirect: '/matching/index',
  //   children: [
  //     {
  //       path: '/matching/index',
  //       meta: { path: '/matching/index', title: '匹配精灵' },
  //       component: () => import('@/views/matching/index.vue')
  //     },
  //     {
  //       path: '/matching/detail',
  //       hidden:true,//不在导航列表中显示
  //       component: () => import('@/views/matching/components/detail.vue')
  //     },
  //   ]
  // },
]


const router = new VueRouter({
  // mode:'hash',
  routes
})

export default router


router.beforeEach((to, from, next) => {
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // }

  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  next()
})

