<template>
    <div class="main">
            <keep-alive>
                <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>

<script>
export default {
    data() {
        return {
         
        }
    },
    created() {
    },
    methods: {

    },

}
</script>

<style lang="scss" scoped>
.main{
    padding-top: 20px;
}
// .slide-right-enter-active,
// .slide-right-leave-active,
// .slide-left-enter-active,
// .slide-left-leave-active {
//     will-change: transform;
//     transition: all 500ms;
//     position: fixed;
// }

// .slide-right-enter {
//     opacity: 0;
//     transform: translate3d(-100%, 0, 0);
// }

// .slide-right-leave-active {
//     opacity: 0;
//     transform: translate3d(100%, 0, 0);
// }

// .slide-left-enter {
//     opacity: 0;
//     transform: translate3d(100%, 0, 0);
// }
// .slide-left-leave-active {
//     opacity: 0;
//     transform: translate3d(-100%, 0, 0);
// }
</style>