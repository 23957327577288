export default {
    /**
     * @description 配置显示在浏览器标签的title
     */
    title: 'xxx信息科技',

    /** 公司名称 */
    name: '上海xxx信息科技有限公司',
    /** 公司微信 */
    wechat: 'GZWjxxxt',
    /** 公司邮箱 */
    email: 'wjhsmarter@xx.com',
    /** 公司手机 */
    phone: '18279xx225',
    /** 公司电话 */
    tel: '021-182xxx225',
    /** 公司地址 */
    address: '浦东新区xxx大厦1号楼5楼703',
    /** 备案号 */
    keepOnRecordIcp: '沪ICP备xxx号',

    /** 百度地图相关信息 */
    BDMapInfo: {
        longitude: 121.526896,
        latitude: 31.24022,
        title: 'xxx大厦',
        address: '浦东新区xxx大厦1号楼5楼xxx'
    },
    // baseUrl:'http://82.156.124.182:8081'
    baseUrl:'https://www.qctgyjs.com/prod-api'
    // baseUrl: 'http://192.168.1.15:8081'
}