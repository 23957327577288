import router from '@/router'

const state = {
    routePath: [],
}
const getters = {
    
}
const mutations = {
      getPath(state,data){
          let list=[]
           data.forEach(e => {
              if(e.meta){
                 list.push(e)
              }
           });
           state.routePath=list
      }
}
const actions = {

}

export default {
    //命名空间
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}