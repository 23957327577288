
<template>
  <div class="app">
    <headerVue v-if="$route.path !='/404'"></headerVue>
    <router-view />
    <footerVue v-if="$route.path !='/404'"></footerVue>
    <message-vue v-if="$route.path!='/404'"></message-vue>
  </div>
</template>

<script>
import headerVue from '@/components/header';
import footerVue from '@/components/footer';
import messageVue from '@/components/message.vue';
  export default {
     components:{
      headerVue,
      footerVue,
      messageVue
     }
  } 

</script>

<style lang="scss" scoped>
.app{
  position: relative;
  width: 100%;
  height: 100%;
}
</style> 