<template>
  <div  class="footer">
     <div class="content df">
       <div class="footer-left df">
         <div class="comm">
           <div class="comm-box" v-for="(item,index) in aboutList" :key="item.id">
              <div class="item" @click="$router.push(item.path)">{{item.title}}</div>
           </div>
         </div>
         <div class="comm">
           <div class="comm-box" v-for="(item,index) in caseList" :key="item.id">
              <div class="item" @click="$router.push(item.path)">{{item.title}}</div>
           </div>
         </div>
         <div class="comm">
           <div class="comm-box" v-for="(item,index) in productList" :key="item.id">
              <div class="item" @click="$router.push(item.path)">{{item.title}}</div>
           </div>
         </div>
         <div class="comm ">
           <div class="comm-box" v-for="(item,index) in matchList" :key="item.id">
              <div class="item" style="width: 100%;" @click="$router.push(item.path)">{{item.title}}</div>
           </div>
         </div>
         <div class="comm end-comm">
           <div class="comm-box" v-for="(item,index) in consultList" :key="item.id">
              <div class="item" @click="$router.push(item.path)">{{item.title}}</div>
           </div>
         </div>
         <div class="comm end-comm" >
           <div class="comm-box" v-for="(item,index) in addList" :key="item.id">
              <div class="item" @click="$router.push(item.path)">{{item.title}}</div>
           </div>
         </div>
       
       </div>
       <!-- 手机端 -->
        <div class="footer-phone">
          <div v-for="(item,index) in phoneList" :key="item.id">
            <div class="item"@click="$router.push(item.path)">{{item.title}}</div>
          </div>
        </div>

       <div class="footer-right">
          <p class="we" style="cursor: pointer;">联系我们</p>
          <p>营业执照：<span>{{ companyMessage.blicense }}</span></p> 
          <p>地址：<span>{{ companyMessage.address }}</span></p> 
          <p>邮编：<span>{{ companyMessage.postcode }}</span></p>
          <p>电话：<span>{{ companyMessage.phone }}</span></p>
          <p>传真：<span>{{ companyMessage.fax }}</span></p>
          <div class="code">
           <img :src="baseUrl+companyMessage.weixin" alt="">
          </div>
       </div>
     </div>
     <div class="desc df">
        <div class="about">{{siteMessage.title}}</div>
        <a class="record" href="https://beian.miit.gov.cn/">{{siteMessage.icp}}</a>
     </div>
  </div>
</template>
<script>
import { getFooterData,getCompanyData } from '@/api/home';
import config from '@/config';
export default {
  name: "Footer",
       data(){
        return{
          baseUrl:config.baseUrl,
          aboutList:[
            {
              id:1,
              title:'关于全策通',
              path:'/about/index'
            },
            {
              id:2,
              title:'企业简介',
              path:'/about/blurb'
            },
            {
              id:3,
              title:'企业文化',
              path:'/about/culture'
            },
            {
              id:4,
              title:'经营理念',
              path:'/about/idea'
            },
          ],
          caseList:[
            {
              id:1,
              title:'客户案例',
              path:'/case/index'
            },
            {
              id:2,
              title:'工业咨询案例',
              path:"/case/advice"
            },
            {
              id:3,
              title:'EAG评估',
              path:"/case/eag"
            },
            {
              id:4,
              title:'低碳节能案例',
              path:"/case/lowCarbon"
            },
            {
              id:5,
              title:'成果转化案例',
              path:"/case/outcome"
            }
          ],
          productList:[
            {
              id:1,
              title:'产品&服务',
              path:"/product/index"
            },
            {
              id:2,
              title:'双碳咨询',
              path:"/product/bicarbon"
            },
            {
              id:3,
              title:'成果转化',
              path:"/product/conversion"
            },
            {
              id:4,
              title:'DCMM',
              path:"/product/dcmm"
            },
            {
              id:5,
              title:'ESG咨询',
              path:"/product/esg"
            },
            {
              id:6,
              title:'两化融合',
              path:"/product/fuse"
            },
            {
              id:7,
              title:'信息化项目',
              path:"/product/message"
            },
            {
              id:8,
              title:'项目申报',
              path:"/product/project"
            },
            {
              id:9,
              title:'产业研究',
              path:"/product/research"
            },
            {
              id:10,
              title:'绿色制造体系',
              path:"/product/green"
            }
          ],
          consultList:[
            {
              id:1,
              title:'咨询中心',
              path:'/consult/index'
            },
            {
              id:2,
              title:'行业动态',
              path:'/consult/industry'
            },
            {
              id:3,
              title:'公司动态',
              path:'/consult/company'
            }, 
          ],
          addList:[
            {
              id:1,
              title:'加入我们',
              path:'/add/index'
            },
            {
              id:2,
              title:'校园招聘',
              path:'/add/campus'
            },
            {
              id:3,
              title:'社会招聘',
              path:'/add/society'
            }, 
          ],
          matchList:[
            {
              id:1,
              title:'政策大模型云平台',
              path:'/matching'
            },
            {
              id:2,
              title:'政策大模型云平台',
              path:'/matching'
            },
          ],
          phoneList:[
            {
              id:1,
              title:'关于全策通',
              path:'/about/index'
            },
            {
              id:2,
              title:'客户案例',
              path:'/case/index'
            },
            {
              id:3,
              title:'产品&服务',
              path:"/product/index"
            },
            {
              id:4,
              title:'咨询中心',
              path:'/consult/index'
            },
            {
              id:5,
              title:'加入我们',
              path:'/add/index'
            },
          ],
          siteMessage:{},
          companyMessage:{}
        }
       },
       mounted(){
        this.getSiteData()
        this.getCompany()
       },
       methods:{
          getSiteData(){
            getFooterData().then(res=>{
               this.siteMessage=res.data.data[0]
            })
          },
          getCompany(){
            getCompanyData().then(res=>{
              this.companyMessage=res.data.data[0]
            })
          }
       }
};
</script>
<style scoped lang="scss">

$type: screen;
$proto: max-width;
$value: 600px;

.footer {
  color: #fff;
  background: #474747;
  overflow: hidden;
  text-align: center;
  padding: 2% 4%;
  .footer-left{
    width: 60%;
    flex-wrap: wrap;
    .comm{
      margin-left: 50px;
      .comm-box{
        .item{
          width: 120px;
          color: #777777;
          font-size: 14px;
          margin-bottom: 10px;
          text-align: left;
          &:hover{
            color: #fff;
            cursor: pointer;
          }
        }
        &:first-child .item{
          font-size: 18px;
          border-bottom: 1px solid #777777;
          padding-bottom: 15px;
        }
      }
    }
    .end-comm{
      margin-top: -140px;
    }
  }
  .footer-right{
    height: 100%;
    padding-left: 100px;
    border-left: 1px solid #777777;
    color: #777777;
    text-align: left;
    p{
      line-height: 30px;
      &:hover{
        color: #fff;
      }
    }
    .we{
      font-size: 18px;
    }
    .firm{
      font-size: 17px;
      margin: 10px 0;
    }
    .code{
      margin-top: 20px;
      width: 150px;
      img{
        width: 100%;
      }
    }
  } 
  .desc{
    border-top: 1px solid #777777;
    margin-top: 40px;
    padding-top: 20px;
    color: #777777;
    line-height: 15px;
    .about{
      border-right:3px solid #777777 ;
      padding-right: 10px;
      margin-right: 10px;
    }
    .record{
      cursor: pointer;
      color: #777777;
      &:hover{
        color: #fff;
      }
    }
    
  }
  .footer-phone{
    display: none;
  }
}



@media #{$type} and ($proto: $value) {
  .footer-left{
    display: none;
 
  }
  .footer-right{
    flex: 1;
  }
  .footer-phone{
    display: block !important;
    margin-right: 20px;
    .item{
      line-height: 30px;
      color:#777777 ;
      border-bottom: 1px solid #777777;
      text-align: left;
    }
  }
  .footer-right{
    padding-left: 15px !important;
    img{
      width: 100px !important;
    }
  }
}

</style>
