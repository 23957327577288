<template>
    <div class="nav-bar df">
        <!-- 面包屑 -->
        <p>您当前所在位置:</p>
        <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item v-for="item in breadList" :key="item.path" :to="item.path">{{item.title}}</el-breadcrumb-item>
        </el-breadcrumb>  
    </div>
</template>

<script>
export default {
    data(){
        return{
            breadList:[]
        }
    },
    created(){
       this.calcBreadList()
    },
    methods:{
        //计算面包屑路径
        calcBreadList(){
              let temp=[{path:'/',title:'首页'}]
              this.$route.matched.forEach(v=>{
                // //判断如果有title 就把当前meta 添加进去
                if(v.meta?.title){
                   temp.push(v.meta)
                }
              })
              this.breadList=temp
           },
    },
    watch:{
        //  1. 监听路由变化
          $route(){
            // 计算
             this.calcBreadList()
          },
    }
}
</script>

<style lang="scss" scoped>
$type: screen;
$proto: max-width;
$value: 600px;
.nav-bar{
    align-items: center;
    p{
        font-size: 14px;
        margin-right: 10px;
    }
    @media #{$type} and ($proto: $value) {
        display: none;
      }
}
</style>