//axios 公共工具函数

//引入axios
import axios from 'axios'
//引入store库
import store from '@/store'

import { Message,Loading } from 'element-ui'


//封装公共地址

//封装超时时间

const instance =axios.create({
    baseURL:process.env.VUE_APP_BASE_API, // url = base url + request url
    timeout:10000
})

//封装请求拦截器
instance.interceptors.request.use(
    // config 请求信息对象
    config =>{
        return config
    },
    err=>{
        return Promise.reject(err)
    }
)
//封装响应拦截器
instance.interceptors.response.use(
    response=>{
        let {code,msg}=response.data
        if(code===0){
            Message.success(msg)   
        }
        if(code===1){
            Message.error(msg)   
        }

        if(code===5001){
            Message.error(msg)   
        }
        return response
    },
    err=>{
        return Promise.reject(err)
    }
)

export default instance