<template>
    <div class="message">
        <div class="content">
            <div class="item reply">
                <el-popover placement="left" title="在线留言" width="200" trigger="hover" ref="popoverRef" hide-after="1000"
                    @hide="hidePopver">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                        <el-form-item prop="name">
                            <el-input v-model="ruleForm.name" placeholder="姓名"></el-input>
                        </el-form-item>
                        <el-form-item prop="phone">
                            <el-input v-model="ruleForm.phone" placeholder="电话"></el-input>
                        </el-form-item>
                        <el-form-item prop="demand">
                            <el-input type="textarea" v-model="ruleForm.demand" placeholder="需求"></el-input>
                        </el-form-item>
                        <el-form-item style="text-align: center;">
                            <el-button type="primary" @click="submitForm('ruleForm')" style="width: 50%;">提交</el-button>
                        </el-form-item>
                    </el-form>
                    <i class="iconfont icon-nankefu" slot="reference"></i>
                </el-popover>
            </div>
            <div class="item" @mouseover="isPhone = true" @mouseout="isPhone = false">
                <i class="iconfont icon-dianhua"></i>
            </div>
            <div class="item" @mouseover="isWechat = true" @mouseout="isWechat = false">
                <i class="iconfont icon-weixin"></i>
            </div>
        </div>
        <div class="phone" v-show="isPhone">{{ companyMessage.phone }}</div>
        <div class="wechat" v-show="isWechat"> <img :src="baseUrl + companyMessage.weixin" alt=""></div>
    </div>
</template>


<script>
import { sendMessage } from '@/api/home.js';
import { getCompanyData } from '@/api/home';
import config from '@/config';
export default {
    data() {
        //自定义规则
        let validatePhone = (rule, value, callback) => {
            if (!value) {
                callback(new Error('手机号不能为空！'));
            }
            //正则表达式
            if (!/^1[3456789]\d{9}$/.test(value)) {
                callback(new Error('手机号不正确！'));
            } else {
                callback();
            }
        };
        return {
            isPhone: false,
            isWechat: false,
            companyMessage: {},
            baseUrl: config.baseUrl,
            ruleForm: {
                name: '',
                phone: '',
                demand: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入名字', trigger: 'blur' },
                    { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                ],
                phone: [
                    { validator: validatePhone, trigger: 'blur' }
                ],
                demand: [
                    { required: true, message: '请填写需求', trigger: 'blur' },
                    { min: 1, max: 10, message: '最大1000字', trigger: 'blur' }
                ]
            }
        }
    },
    mounted() {
        this.getCompany()
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                let qctMessage = this.ruleForm
                if (valid) {
                    sendMessage(qctMessage).then(res => {
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                        this.$refs.popoverRef.doClose();
                        this.$refs[formName].resetFields();
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        getCompany() {
            getCompanyData().then(res => {
                this.companyMessage = res.data.data[0]
            })
        },
        hidePopver() {
            this.$refs['ruleForm'].resetFields();
        },

    }
}
</script>

<style lang="scss" scoped>
$type: screen;
$proto: max-width;
$value: 600px;

.message {
    position: fixed;
    bottom: 100px;
    right: 10px;
    z-index: 999999;

    .content {
        position: relative;

        .item {
            width: 35px;
            height: 35px;
            background-color: #f3f3f3;
            margin-top: 2px;
            padding: 5px;
            text-align: center;
            line-height: 40px;
        }

        i {
            font-size: 25px;

            &:hover {
                color: #409EFF;
            }
        }

        .reply {
            .demo-ruleForm {
                width: 100%;
            }


        }

    }

    .phone {
        position: absolute;
        text-align: center;
        line-height: 45px;
        top: 49px;
        right: 48px;
        width: 150px;
        background-color: #f3f3f3;
        font-size: 17px;
    }

    .wechat {
        position: absolute;
        bottom: 0;
        right: 48px;

        img {
            width: 120px;
            height: 120px;
        }
    }
}

@media #{$type} and ($proto: $value) {
    .message {
        bottom: 50px !important;

        .item {
            width: 30px !important;
            height: 30px !important;
        }

        .wechat {
            img {
                width: 80px;
                height: 80px;
            }
        }
    }
}
</style>